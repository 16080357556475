import React from 'react';
import './side-navi.scss';

import useHeaderState from '../../store/header';
import Bundle from '../../data/bundle';
import * as StringUtil from '../../utils/string';

const SideNavi = () => {

  const {header} = useHeaderState();
  const menuClass = (header.menuOpen) ? " open" : " close"

  return (
    <div className={"side-navi"+menuClass}>
      {Object.keys(Bundle).map((key, i) => {
        let item = Bundle[key];
        return (
          <div className="level-one" key={i}>
            <a href={"#"+StringUtil.textToKebabCase(item['Name'])} className="name">{item['Name']}</a>
            {(item['Items'])?
              item['Items'].map((item2, i2) => {
                return(
                  <div className="level-two" key={i2}>
                    <a href={"#"+StringUtil.textToKebabCase(item2['Name'])} className="name">{item2['Name']}</a>
                    {(item2['Items'])?
                      item2['Items'].map((item3, i3) => {
                        return(
                          <div className="level-three" key={i3}>
                            <a href={"#"+StringUtil.textToKebabCase(item3['Name'])} className="name">{item3['Name']}</a>
                          </div>
                        )
                      })
                    :null}
                  </div>
                )
              })
            :null}
          </div>
        )
      })}
    </div>
  );

};

export default SideNavi;