
import {Environment,EnvironmentEnum} from './env';

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3082",
    ApiBaseSocket: "ws://localhost:3082",
    AppName: "Fjorney",
    Company: "Fjorney",
    CompanyLink: "https://fullfour.com/",
    FrontEndUrl: "http://localhost:3080",
    ApiDocsUrl: "http://localhost:3083",
    SupportEmailName: "mail",
    SupportEmailDomain: "gmail.com",

  },
  Keys:{
    StripePublicKey: "",
    StripeAnnualPriceId: "",
    StripeMonthlyPriceId: "",
    GoogleAnalytics: "",
    RecaptchaPubKey: "6Lf6fvccAAAAAL0eCyCXdJgXz4OVrsiKvPROqRzS",
  },
};

if(Environment === EnvironmentEnum.Release){
  // Config.Common.ApiBaseUrl = "https://..co";
  // Config.Common.ApiBaseSocket = "wss://..co";
  // Config.Common.FrontEndUrl = "https://..co";
  // Config.Common.ApiDocsUrl = "https://..co";
}


if(Environment === EnvironmentEnum.Production){
  Config.Common.ApiBaseUrl = "https://api.fjorney.comm";
  Config.Common.ApiBaseSocket = "wss://api.fjorney.com";
  Config.Common.FrontEndUrl = "https://fjorney.com";
  Config.Common.ApiDocsUrl = "https://docs.fjorney.com";

  Config.Keys.RecaptchaPubKey = "6Le-ffccAAAAANhI5Fl-LYbbir38zx76HQdfKLmy";
}


export default Config;
