

import SignCodeExamples,{javaScriptSignCode} from './sign-code-examples';

let queueResponse = 
`// Response example for /prompt
{"err":{"code":0,"message":""},
  "res":{
    "uids":[
      "5FeT8xAeDq7bXUZ3HSbe5M",
      "5GDvUiaSKH2hxvGthq7QT8"
    ],
  }
}

// Full Example (nodejs)
const crypto = require('crypto'); // run \`npm install crypto\`
const createSignature = (secret, query) => {
  return crypto.createHmac('sha256', secret).update(query).digest('hex');
}

let key = "YOUR API KEY";
let secret = "YOUR API SECRET";
let timestamp = new Date().getTime();
let body = {
  prompts: ["a cool dwarf"],
  callbackUrl: "http://localhost:3035/test", // Your callback domain
  callbackToken: "1234",
};
// let body = {uid:"xbeHjkPQPHeGrEodUSaXcw"}; // For /image
let query = JSON.stringify(body) + timestamp;
let sign = createSignature(secret, query);
let endpoint = "http://localhost:3085/v1/prompt";
// let endpoint = "http://localhost:3085/v1/image"; // For /image
let method = "POST";
let headers = {
  'FJORNEY-KEY':key,
  'FJORNEY-SIGN':sign, 
  'FJORNEY-TIMESTAMP':timestamp, 
}
r = await request("test", endpoint, method, headers, body); // Your request function
if(r.err.code) return r;
d.res = r.res;


// CallbackUrl body example
{
  "uid": "5FeT8xAeDq7bXUZ3HSbe5M",
  "imageUrl": "https://linktoUrl.com/image.png", // example url
  "aspectRatio": "1:1",
  "prompt": "a cool elf",
  "finished": false, // true
  "token":"", // if you set a token, it will be here
}`;

let imageResponse = 
`// Response example for /image (result not ready)
{"err":{"code":0,"message":""},
  "res":{
    "prompt":{
      "uid":"6m8BeBdfvu6Ziivnc3XWwQ",
      "aspectRatio":"1:1",
      "prompt":"a cool dwarf",
      "json":"",
      "status":0,
      "repeat":0,
      "createdDate":"2023-12-06T04:27:50.000Z",
      "orderDate":"2023-12-06T04:27:50.000Z",
      "processDate":null,
      "updatedDate":"2023-12-06T04:27:50.000Z"
    },
    "image":{}
  }
}

// Response example for /image (result ready)
{"err":{"code":0,"message":""},
  "res":{
    "prompt":{},
    "image":{
      "uid":"xbeHjkPQPHeGrEodUSaXcw",
      "aspectRatio":"1:1",
      "prompt":"A young elven woman in her early twenties ...",
      "url":"https://cdn.discordapp.com/URL-TO-IMAGE.png",
      "json":"",
      "createdDate":"2023-11-20T07:56:15.000Z"
    }
  }
}`;



let baseurl = "https://api.fjorney.com";

const RestApi = {
  Name:"Rest Api",
  Items:[{
    Name:"Base URL",
    Desc: <div>
      <p>The REST API has endpoints for account and order management as well as public market data.</p>
      <p>The base url is {baseurl}</p>
    </div>, 
  },{
    Name:"Request",
    Desc: <div>
      <p>All requests and responses are application/json content type.</p>
      <p>Unless otherwise stated, all timestamp parameters should in milliseconds. e.g. 1624237964732</p>
    </div>, 
  },{
    Name:"Parameters",
    Desc: <div>
      <p>For GET requests, all query parameters need to be included in the request url. </p>
      <p>For POST, PATCH and DELETE requests, all query parameters need to be included in the request body with JSON. </p>
    </div>, 
  },{
    Name:"Errors",
    Desc: <div>
      <p>When errors occur, an error response object. Any code other than 0 is an error. e.g. {'{err:{code:1,message:"Message of the error"},res:{}}'}</p>
    </div>, 
  },{
    Name:"Authentication",
    Items:[{
      Name:"Create an Api Key",
      Desc: <div>
        <p>To intereact with your Fjorney account via API you'll need a API Key. You can get this via your My Account page.</p>
      </div>, 
    },{
      Name:"Creating a Request",
      Desc: <div>
        <p>All private REST requests must contain the following headers:</p>
        <ul>
          <li>FJORNEY-KEY The API key as a string.</li>
          <li>FJORNEY-SIGN The signature (see Signing a Message).</li>
          <li>FJORNEY-TIMESTAMP A timestamp for your request.</li>
          <li>(optional) FJORNEY-MINENFORCETIME Minimum milliseconds to enforce the request. </li>
        </ul>
      </div>, 
      CodeExamples: SignCodeExamples,
      Code: javaScriptSignCode,
    }],
  },{
    Name:"Prompts & Images",
    Desc: "All endpoints here require User Auth",
    Items:[{
      Name: "Prompt",
      Url: "POST - /v1/prompt",
      Desc: 
      <div>
        <h5>Parameters</h5>
        <table>
          <tbody>
            <tr><th>Param</th><th>Type</th><th>Description</th></tr>
            <tr><td>prompts</td><td>array of strings</td><td>["A cool elf","A cool dwarf"]</td></tr>
            <tr><td>prompts</td><td>array of objects</td><td>{`[{"prompt":"A cool elf",aspectRatio:"7:4"},{"prompt":"A cool dwarf",aspectRatio:"7:4"}]`}</td></tr>
            <tr><td>callbackUrl</td><td>string (optional)</td><td>Url to callback when image updated. Will be a POST with uid of image & a finished Boolean. </td></tr>
            <tr><td>callbackToken</td><td>string (optional)</td><td>Token to be passed back in the callback</td></tr>
          </tbody>
        </table>
      </div>,
      Code:queueResponse
    },{
      Name: "Images",
      Url: "POST - /v1/image",
      Desc: 
      <div>
        <h5>Parameters</h5>
        <table>
          <tbody>
            <tr><th>Param</th><th>Type</th><th>Description</th></tr>
            <tr><td>uid</td><td>string</td><td>6m8BeBdfvu6Ziivnc3XWwQ</td></tr>
          </tbody>
        </table>
      </div>,
      Code:imageResponse
    }],
  }], 
};
export default RestApi;