const General = {
  Name:"General",
  Items:[{
    Name:"Introduction",
    Desc: <div>
      <p>Welcome to Fjorney's developer documentation. These documents outline the functionality, details, and APIs of Fjorney.</p>
      <p>The whole documentation is divided into two parts: REST API and Websocket feed.</p>
      <p>The REST API contains four sections: User(private), and Others(public).</p>
      <p>The WebSocket contains two sections: Public Channels and Private Channels</p>
    </div>, 
  }],
  
};

export default General;