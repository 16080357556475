import React from 'react';
import './home.scss';
import './syntax.scss';
import * as StringUtil from '../../../utils/string';
import Bundle from '../../../data/bundle';
// import Carousel from '../../common/carousel/carousel';
// import CarouselHeader from '../../common/carousel/carousel-header';
import Highlight from "react-highlight";

const Home = () => {

  // let [slide,setSlide] = useState();

  return (
    <div className="home-comp">
      <div className="text-block">
        {Object.keys(Bundle).map((key, i) => {
          let item = Bundle[key];
          return (
            <div className="level-one" key={i} id={StringUtil.textToKebabCase(item['Name'])}>
              <div className="block">
                <div className="text">
                  <h2 className="name">{item['Name']}</h2>
                  {(item['Url'])?<div className="url">{item['Url']}</div>:null}
                  <div className="desc">{item['Desc']}</div>
                </div>
                <div className="code">
                  {(item['Code'])?<Highlight language="javascript">
                  {item['Code']}
                  </Highlight>:null}
                </div>
              </div>
              {(item['Items'])?
                item['Items'].map((item2, i2) => {
                  return(
                    <div className="level-two" key={i2} id={StringUtil.textToKebabCase(item2['Name'])}>
                      <div className="block">
                        <div className="text">
                          <h3 className="name">{item2['Name']}</h3>
                          {(item2['Url'])?<div className="url">{item2['Url']}</div>:null}
                          <div className="desc">{item2['Desc']}</div>
                        </div>
                        <div className="code">
                          {(item2['Code'])?<Highlight language="javascript">
                          {item2['Code']}
                          </Highlight>:null}
                        </div>
                      </div>
                      {(item2['Items'])?
                        item2['Items'].map((item3, i3) => {
                          console.log("item3",item3)
                          return(
                            <div className="level-two" key={i3} id={StringUtil.textToKebabCase(item3['Name'])}>
                              <div className="block">
                                <div className="text">
                                  <h4 className="name">{item3['Name']}</h4>
                                  {(item3['Url'])?<div className="url">{item3['Url']}</div>:null}
                                  <div className="desc">{item3['Desc']}</div>
                                </div>
                                {/* {(item3["CodeExamples"])?
                                  <div className='code3'>
                                    <CarouselHeader>
                                      <div>hiya</div>
                                      <div>hiya2</div>
                                    </CarouselHeader>
                                    <Carousel>
                                      <div><div>hiya</div></div>
                                      <div><div>hiya2</div></div>
                                    </Carousel> 
                                  
                                  </div>
                                :null} */}
                                <div className="code">
                                  {(item3['Code'])?<Highlight language="javascript">
                                  {item3['Code']}
                                  </Highlight>:null}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      :null}
                    </div>
                  )
                })
              :null}
            </div>
          )
        })}
      </div>
      <div className="code-block"></div>
    </div>
  );
};

export default Home;