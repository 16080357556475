import React from 'react';
import './top-navi.scss';

import Config from '../../config/config';
import useHeaderState from '../../store/header';
import {ReactComponent as MenuSvg} from '../../css/imgs/icon-menu.svg';
import Logo from '../common/logo/logo';

const TopNavi = () => {

  const {toggleMenu,} = useHeaderState();

  return (
    <div className={"top-navi"}>
      <div className="desktop-top-navi">
        <span className="logo">
          <a href="/">
            <Logo options="white"/>
            <span className="text">| docs</span>
          </a>
        </span>
      </div>
      <div className="mobile-top-navi">
        <button className="menu button" onClick={toggleMenu}><MenuSvg /></button>
        <span className="logo">
          <a href="/">
            <Logo options="white"/>
            <span className="text">| docs</span>
          </a>
        </span>
        <button className="blank"></button>
      </div>
    </div>
  );

};

export default TopNavi;