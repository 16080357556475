
import General from './general';
import RestApi from './rest-api';
// import Socket from './socket';

const Bundle = {
  General,
  RestApi,
  // Socket,
};

export default Bundle;