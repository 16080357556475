export const javaScriptSignCode = 
`// Sign Example In JavaScript
const crypto = require('crypto'); 

let secret = "YOUR API SECRET";
let timestamp = new Date().getTime();
let body = {}; // Define body appropriately

const createSignature = (secret, query) => {
  return crypto.createHmac('sha256', secret).update(query).digest('hex');
}

let query = JSON.stringify(body) + timestamp;
let sign = createSignature(secret, query);
`;

export const pythonSignCode = 
`import hmac
import hashlib
import time
import json

secret = "YOUR API SECRET"
timestamp = int(time.time() * 1000)
body = {}  # Define your body dictionary here

def create_signature(secret, query):
    return hmac.new(secret.encode(), query.encode(), hashlib.sha256).hexdigest()

query = json.dumps(body) + str(timestamp)
signature = create_signature(secret, query)

print("Signature:", signature)`;

export const phpSignCode = 
`<?php
$secret = "YOUR API SECRET";
$timestamp = round(microtime(true) * 1000);
$body = []; // Define your body array here

function createSignature($secret, $query) {
    return hash_hmac('sha256', $query, $secret);
}

$query = json_encode($body) . $timestamp;
$signature = createSignature($secret, $query);

echo "Signature: " . $signature;
?>`;

export const javaSignCode = 
`import javax.crypto.Mac;
import javax.crypto.spec.SecretKeySpec;
import java.nio.charset.StandardCharsets;
import java.security.InvalidKeyException;
import java.security.NoSuchAlgorithmException;

public class SignatureExample {
  public static String createSignature(String secret, String query) throws NoSuchAlgorithmException, InvalidKeyException {
    Mac sha256_HMAC = Mac.getInstance("HmacSHA256");
    SecretKeySpec secret_key = new SecretKeySpec(secret.getBytes(StandardCharsets.UTF_8), "HmacSHA256");
    sha256_HMAC.init(secret_key);

    return bytesToHex(sha256_HMAC.doFinal(query.getBytes(StandardCharsets.UTF_8)));
  }

  private static String bytesToHex(byte[] bytes) {
    StringBuilder hexString = new StringBuilder();
    for (byte b : bytes) {
      String hex = Integer.toHexString(0xff & b);
      if (hex.length() == 1) hexString.append('0');
      hexString.append(hex);
    }
    return hexString.toString();
  }

  public static void main(String[] args) throws NoSuchAlgorithmException, InvalidKeyException {
    String secret = "YOUR API SECRET";
    long timestamp = System.currentTimeMillis();
    String body = "{}"; // Replace with your JSON string

    String query = body + timestamp;
    String signature = createSignature(secret, query);

    System.out.println("Signature: " + signature);
  }
}`;

export const golangSignCode = 
`package main

import (
	"crypto/hmac"
	"crypto/sha256"
	"encoding/hex"
	"encoding/json"
	"fmt"
	"time"
)

func createSignature(secret, query string) string {
	mac := hmac.New(sha256.New, []byte(secret))
	mac.Write([]byte(query))
	return hex.EncodeToString(mac.Sum(nil))
}

func main() {
	secret := "YOUR API SECRET"
	timestamp := time.Now().UnixNano() / int64(time.Millisecond)
	body := map[string]interface{}{} // Define your body map here

	bodyBytes, _ := json.Marshal(body)
	query := string(bodyBytes) + fmt.Sprint(timestamp)
	signature := createSignature(secret, query)

	fmt.Println("Signature:", signature)
}`;

export const rubySignCode = 
`require 'openssl'
require 'json'
require 'time'

secret = "YOUR API SECRET"
timestamp = Time.now.to_i * 1000
body = {} # Define your body hash here

def create_signature(secret, query)
  OpenSSL::HMAC.hexdigest('sha256', secret, query)
end

query = body.to_json + timestamp.to_s
signature = create_signature(secret, query)

puts "Signature: #{signature}"
`;


const SignCodeExamples = [
  {lang:"JavaScript",code: javaSignCode},
  {lang:"Python",code: pythonSignCode},
  {lang:"PHP",code: phpSignCode},
  {lang:"Java",code: javaSignCode},
  {lang:"GoLang",code: golangSignCode},
  {lang:"Ruby",code: rubySignCode},
];

// const SignCodeExamples = {
//   "JavaScript": javaScriptSignCode,
//   "Python": pythonSignCode,
//   "PHP": phpSignCode,
//   "Java": javaSignCode,
//   "GoLang": golangSignCode,
//   "Ruby": rubySignCode,
// };

export default SignCodeExamples