
import React from 'react';
import './css/styles/app.scss';
import './css/styles/common.scss';
import './css/styles/toastify.scss';

import { Router, Route, Switch, } from 'react-router-dom';
import history from './store/history';

import SideNavi from './components/navi/side-navi';
import TopNavi from './components/navi/top-navi';
import NaviGrayScreen from './components/navi/navi-gray-screen';


import Home from './components/views/home/home';
import NotFound from './components/views/not-found/not-found';
import NormRoute from './components/common/routes/norm-route';

import packageJson from '../package.json';
import {Environment} from './config/env';
import Test from './components/views/test/test';

console.log("App Version: ",packageJson.version);
console.log("React Version: ",React.version);
console.log("Environment: ",Environment);

const App = () => {

  const subDirectory = "/";

  return (
    <Router basename={subDirectory} history={history}>
      <div className={"app"}>
        <TopNavi />
        <div id="content-container">
          <SideNavi />
          <NaviGrayScreen />
          <div id="content">
            <Switch>
              <NormRoute exact path="/" component={(props) => <Home history={props.history} />} />  
              <Route path="/test" component={Test} />  
              <Route component={NotFound} />  
            </Switch>
          </div>
        </div>

      </div>
    </Router> 
  );
}

export default App;
