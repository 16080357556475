import React from 'react';
import './navi-gray-screen.scss';

import useHeaderState from '../../store/header';

const NaviGrayScreen = () => {

  const {toggleMenu,header} = useHeaderState();
  const menuClass = (header.menuOpen) ? " open" : " close"

  return (
    <div className={"navi-gray-screen"+menuClass} onClick={toggleMenu}>
      
    </div>
  );

};

export default NaviGrayScreen;